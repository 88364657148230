import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons';
import PostLink from "../components/post-link";
import Departments from "../data/departments.yml";
import Projects from "../data/projects.yml";
import Members from "../data/members.yml";
import AvekshaStyle from "../components/aveksha.module.css";
export const pageQuery = graphql`
  query AllBlogPosts {
    site {
      siteMetadata {
        description
        title
      }
    }
    allSitePage (
      filter: { context: { frontmatter: { collection: { eq: "blog" } } } }
      sort: { order: DESC, fields: context___frontmatter___date }
    ) {
      edges {
        node {
          id
          context {
            frontmatter {
              date
              path
              title
            }
          }
        }
      }
    }
  allMembersYaml {
    edges {
      node {
        name
        image {
          childImageSharp {
            fluid (maxWidth: 150, maxHeight: 150) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
}
`;
export const _frontmatter = {};
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <br />
    <hr />
    <div className={AvekshaStyle.alertBox}>
  <Link to="/aveksha/covid-19" mdxType="Link"><span className={AvekshaStyle.blink}>COVID-19 Resources</span></Link>
    </div>
    <div className={AvekshaStyle.alertBox}>
  <a href="https://drive.google.com/file/d/1zVi9xXFGbOP-hx4Tj7uhZtnuIlnsJj-B/view?usp=sharing">Click Here To View Our Currently Available Services</a>
    </div>
    <div className={AvekshaStyle.book2} style={{
      textAlign: "center"
    }}>
      <a style={{
        flex: 1
      }} href="tel:+918971544066">📱8971544066</a>
    </div>
    <br />
    <hr />
    <div className="departments">
  {Departments.map(department => <div className="department" style={{
        'background-color': department.color
      }}>
      <Link to={department.page} mdxType="Link">
        <div className="name">{department.name}</div>
        <div className="description">{department.description} [...]</div>
      </Link>
    </div>)}
    </div>
    <br />
    <h2>{`We are different`}</h2>
    <p>{`We focus on exercising courtesy and compassion with our patients because we believe that patients respond better to a physician who is empathetic to their needs`}</p>
    <h2>{`You are at the center of the conversation`}</h2>
    <p>{`We don't believe in the plain old one-sided conversations. We listen to what you have to say and we believe in empowering you.`}</p>
    <h2>{`Physicians who go beyond`}</h2>
    <p>{`A physician is obligated to consider more than a diseased organ, more even than the whole man - they must view the man in his world`}</p>
    <section id="team" className="align-center">
      <h2> Our team</h2>
      <div className="members-container">
        {props.data.allMembersYaml.edges.map(({
          node
        }) => <div className='member-container'>
      <div className="member-image">
        <Img fluid={node.image.childImageSharp.fluid} mdxType="Img" />
      </div>
      <div className="member-name">
        {node.name}
      </div>
    </div>)}
      </div>
    </section>
    <h2 className="align-center">Programs</h2>
    <section id="projects" className="align-center projects">
  {Projects.map(project => <div className="project" style={{
        'background-color': project.color
      }}>
      <Link to={project.page} mdxType="Link">
        <div className="name">{project.name}</div>
        <div className="description">{project.description} [...]</div>
      </Link>
    </div>)}
    </section>
    <section className="location align-center">
  <h2>Reach us</h2>
  <iframe frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src="https://www.openstreetmap.org/export/embed.html?bbox=77.56932377815248%2C12.98500862259466%2C77.57754206657411%2C12.991490320479071&amp;layer=mapnik&amp;marker=12.98824949267772%2C77.57343292236328" style={{
        "border": "1px solid black",
        "height": "70vh"
      }}></iframe>
  <br />
  <small>
    <a href="https://www.openstreetmap.org/?mlat=12.98825&amp;mlon=77.57343#map=17/12.98825/77.57343">
      View Larger Map
    </a>{" "}
    | <a href="https://goo.gl/maps/EuUd6JR7eDuhwPQ58">Google Maps</a>
  </small>
  <p>
    By metro: Get down at <em>Mantri Square Sampige Road</em> station on the
    green line. Walk towards Nataraj Theatre and take right onto the road opposite Karnataka Biryani Point. Look for the board "Curie Diagnostics" on the right (which is opposite Swastik Manandi Arcade).
  </p>
  <p>
    If you find it difficult to get directions please call us at +91-8971544066
  </p>
    </section>
    <section className="location align-center">
      <h2>Social Media</h2>
      <p>{`Follow us on:`}</p>
      <a href="https://www.instagram.com/pcmhrestore/"><FontAwesomeIcon icon={faInstagram} size="5x" mdxType="FontAwesomeIcon" /></a> &nbsp;
      <a href="https://twitter.com/HealthRestore"><FontAwesomeIcon icon={faTwitter} size="5x" mdxType="FontAwesomeIcon" /></a>
      <br />
      <br />
    </section>
    <h1>{`Latest Blog Posts`}</h1>
    <p>{props.data.allSitePage.edges.map(edge => <PostLink post={edge.node.context} mdxType="PostLink" />)}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      